import { mapGetters } from 'vuex'
import Menu from '@/common/Menus/Menu.vue'
export default {
    name: 'ProjectPreview',
    components: {
        Menu
    },
    props: {
        id: [Number, String], //from route prop
        type: { type: String, default: 'project', require: true }
    },
    watch: {
        '$route.params.id': {
            handler: function(val, old) {
                if (val !== old) {
                    this.$store.dispatch('project/fetchProjectInfo', val)
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters('project', ['project']),
        ...mapGetters(['user']),
        ...mapGetters('app', ['allowedModules']),
        tabs() {
            const list = [{
                    active: true,
                    id: 'hq',
                    name: 'HQ',
                    icon: 'mdi-home',
                    route: { path: `/dashboard/${this.type}/preview/${this.id}` }
                },
                {
                    active: true,
                    id: 'project-preview-files',
                    name: 'FILES',
                    icon: 'mdi-file-cabinet',
                    route: { path: `/dashboard/${this.type}/preview/${this.id}/files` }
                },
                {
                    active: true,
                    id: 'project-preview-members',
                    name: 'TEAM',
                    icon: 'mdi-account-group',
                    route: { path: `/dashboard/${this.type}/preview/${this.id}/members` }
                },
                {
                    active: true,
                    id: 'project-preview-milestones',
                    name: 'MILESTONES',
                    icon: 'mdi-clipboard-list-outline',
                    route: {
                        path: `/dashboard/${this.type}/preview/${this.id}/milestones`
                    }
                },
                {
                    active: true,
                    id: 'project-preview-tasks',
                    name: 'TASKS',
                    icon: 'mdi-file-check-outline',
                    route: { path: `/dashboard/${this.type}/preview/${this.id}/tasks` }
                }
            ]
            if (this.is_module_enabled('chat')) {
                list.push({
                    active: true,
                    id: 'project-chats',
                    name: 'MESSAGES',
                    icon: 'mdi-message-bulleted',
                    route: { path: `/dashboard/${this.type}/preview/${this.id}/messages` }
                })
            }
            if (this.is_module_enabled('reports')) {
                list.push({
                    active: true,
                    id: 'project-preview-reports',
                    name: 'REPORTS',
                    icon: 'mdi-google-analytics',
                    route: { path: `/dashboard/${this.type}/preview/${this.id}/reports` }
                })
            }
            if (this.is_module_enabled('task_timers')) {
                list.push({
                    active: true,
                    id: 'project-preview-timer',
                    name: 'TIMERS',
                    icon: 'mdi-timer-outline',
                    route: { path: `/dashboard/${this.type}/preview/${this.id}/timer` }
                })
            }
            if (this.is_module_enabled('invoices')) {
                list.push({
                    active: true,
                    id: 'project-preview-invoice',
                    name: 'INVOICES',
                    icon: 'mdi-receipt',
                    route: { path: `/dashboard/${this.type}/preview/${this.id}/invoice` }
                })
            }
            if (this.user.is_admin || this.user.is_manager) {
                list.push({
                    active: false,
                    id: 'project-preview-schedule-emails',
                    name: 'SCHEDULE EMAILs',
                    icon: 'mdi-email',
                    route: {
                        path: `/dashboard/${this.type}/preview/${this.id}/schedule-emails`
                    }
                })
            }
            return list.filter((i) => i.active)
        }
    },
    methods: {
        is_module_enabled(name) {
            return this.allowedModules.includes(name)
        },
        is_active(tab) {
            return this.$route.name == tab.id
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.verifyProject(to, from, next)
        })
    }
}